.m-0 {
  margin: 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.p-0 {
  padding: 0;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.mx-auto {
  margin: 0 auto;
}

body {
  padding-right: 0 !important;
}
