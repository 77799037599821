.carousel-container .prev:hover,
.carousel-container .next:hover {
  color: white;
  background-color: #d35f2e;
}

img.thumbnail.active-thumbnail {
  border-color: #d35f2e !important;
}

.slide-number {
  border-radius: 10px;
  background-color: #d35f2e;
}