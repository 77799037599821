html {
  height: 100%;
}

body,
#root {
  min-height: 100vh;
}

.list-group-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.header-total-registros {
  text-align: center;
}

.header-cadastrar {
  text-align: right;
}

@media (max-width: 767px) {
  .header-cadastrar {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .course-item .image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
  }

  .course-item .image img {
    display: block;
    margin: 0 auto;
  }
}