label#foto {
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  height: 160px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

label#foto input {
  display: none;
}

label#foto.has-thumbnail {
  /* border: 0; */
}

label#foto.has-thumbnail img {
  display: none;
}
